import { createSignal, Suspense, For, Show } from "solid-js";
import { createAsync, useNavigate } from "@solidjs/router";
import { getOrders, getOrderPDF } from "~/services/orders";
import { getInvoices, getInvoicePDF } from "~/services/invoices";
import { AccountPanel } from "~/components/account";
import { Input, SelectBox } from "~/components/inputs";
import { BaseLoader, BaseSkeleton } from "~/components/utility";
import { Icon } from "solid-heroicons";
import { arrowDownTray } from "solid-heroicons/outline";
import { Invoice } from "~/services/roma-api/invoices/types";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";
import { AuthBarrier } from "~/components/utility/AuthBarrier";

// TODO - getOrderPDF / getInvoicePDF

export default function History() {
  const { addError } = useErrorContext();
  const navigate = useNavigate();
  const [filters, setFilters] = createSignal({
    days: 365,
    from: new Date().toISOString().split("T")[0],
  });
  const [downloadId, setDownloadId] = createSignal<string | null>(null);

  const ordersAndInvoices = createAsync(async () => {
    try {
      const orders = await getOrders(filters());
      const invoices = await getInvoices(filters());

      if (!orders || !invoices) {
        return { Results: [], Total: 0 };
      }

      return {
        From: orders?.From,
        To: orders?.To,
        Total: (orders?.Total ?? 0) + (invoices?.Total ?? 0),
        Results: [...(orders?.Results ?? []), ...(invoices.Results ?? [])],
      };
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[history.tsx]: Error caught in createAsync: ", err);
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Order Error",
          message:
            "An error occurred while retrieving your order history. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Refresh",
              action: () => {
                window.location.reload();
              },
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    }
  });

  const downloadPDF = async (id: string, type: "invoice" | "order") => {
    setDownloadId(id);

    try {
      const blob =
        type === "invoice" ? await getInvoicePDF(id) : await getOrderPDF(id);

      const objectUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = `RomaMoulding-Invoice-${id}.pdf`;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
      setDownloadId(null);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[downloadPDF]: Caught error: ", error);
      }

      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Download error",
        message:
          "An error occurred while trying to download this invoice. Please try again. If the error persists, kindly contact customer support. We apologize for the  inconvenience.",
        actions: [
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });

      setDownloadId(null);
    }

    // const blob =
    //   type === "invoice" ? await getInvoicePDF(id) : await getOrderPDF(id);

    // const objectUrl = window.URL.createObjectURL(blob);
    // const anchor = document.createElement("a");
    // document.body.appendChild(anchor);
    // anchor.href = objectUrl;
    // anchor.download = `RomaMoulding-Invoice-${id}.pdf`;
    // anchor.click();
    // window.URL.revokeObjectURL(objectUrl);
    // setDownloadId(null);
  };

  const filterList = [
    { label: "30 days", value: 30 },
    { label: "60 days", value: 60 },
    { label: "90 days", value: 90 },
    { label: "180 days", value: 180 },
    { label: "365 days", value: 365 },
  ];

  return (
    <AccountPanel>
      <AuthBarrier permissions={"VIEWPURCHASEHISTORY"}>
        <div class="pb-20 max-w-6xl">
          <div class="flex flex-col ">
            <h2 class="text-2xl md:text-3xl font-bold">
              Open Orders & History
            </h2>
            <div class="flex flex-col md:flex-row justify-start md:items-center md:space-x-1 max-md:space-y-1 mt-4">
              <span class="max-md:text-sm">Starting Date: &nbsp;</span>
              <Input
                name="start-range"
                value={filters().from}
                onInput={(e) => {
                  const dt = e.currentTarget.value;
                  if (!isNaN(Date.parse(dt))) {
                    setFilters((prev) => ({ ...prev, from: dt }));
                  }
                }}
                type="date"
                class="text-sm"
              />
              <SelectBox
                options={filterList}
                triggerClass="py-2"
                inlineTitle="Show Last:"
                defaultValue={filters().days}
                onChange={(option) => {
                  // setFilter(option.value as number);
                  setFilters((prev) => ({
                    ...prev,
                    days: option.value as number,
                  }));
                }}
              />
            </div>
          </div>
          {/* TODO - Error Handling */}
          {/* <Show when={ordersAndInvoices()?.Error}>
              <div class="border border-gray-300 bg-red-50 p-4 rounded-md mt-4 text-roma-dark-grey text-sm ">
                Something went wrong while retrieving recent orders, please try again or contact <A href="/support" class="text-roma-blue">support</A> for more assistance. We apologize for the inconvenience.
              </div>
            </Show> */}

          <Suspense
            fallback={
              <div class="flex flex-col gap-3 mt-8">
                <BaseSkeleton height={50} class="mb-10" />
                <For each={new Array(10)}>
                  {(_, i) => <BaseSkeleton height={25} delay={i() * 200} />}
                </For>
              </div>
            }
          >
            <main class="py-3 md:py-10">
              <div class="flex flex-col md:flex-row md:gap-4 pb-3 text-sm text-roma-dark-grey">
                <p>Status:</p>
                <div class="flex items-center gap-1">
                  <div class="w-3 aspect-square rounded-full bg-green-500" />
                  <p>Open Order</p>
                </div>
                <div class="flex items-center gap-1">
                  <div class="w-3 aspect-square rounded-full bg-orange-500" />
                  <p>Invoiced / Due</p>
                </div>
                <div class="flex items-center gap-1">
                  <div class="w-3 aspect-square rounded-full bg-roma-blue" />
                  <p>Invoiced / Paid</p>
                </div>
              </div>
              <table class="table-auto w-full">
                <thead class="bg-roma-grey text-sm text-roma-dark-grey font-normal border-b">
                  <tr class="child:font-medium child:py-2 sticky top-0 z-10 backdrop-blur-sm">
                    <th class="sticky top-0 z-10">Order Details</th>
                    <th class="sticky top-0 z-10 hidden md:table-cell">
                      Billing Date
                    </th>
                    <th class="sticky top-0 z-10 hidden md:table-cell">
                      Reference No.
                    </th>
                    <th class="sticky top-0 z-10 hidden md:table-cell">
                      Purchase Order
                    </th>
                    <th class="sticky top-0 z-10">Amount</th>
                    <th class="sticky top-0 z-10">Actions</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <Show
                    fallback={
                      <tr>
                        <td colspan="5" class="p-32">
                          No invoices were found for the date range{" "}
                          <span class="text-roma-blue">
                            {ordersAndInvoices()?.From}
                          </span>{" "}
                          to{" "}
                          <span class="text-roma-blue">
                            {ordersAndInvoices()?.To}.
                          </span>
                        </td>
                      </tr>
                    }
                    when={ordersAndInvoices()?.Total !== 0}
                  >
                    <For each={ordersAndInvoices()?.Results}>
                      {(order) => (
                        <tr class="child:py-5 border-b hover:bg-roma-grey">
                          <td>
                            <div class="hidden md:grid grid-cols-[max-content_1fr] items-center gap-x-3 text-left pl-5">
                              <div
                                class="w-3 aspect-square rounded-full"
                                classList={{
                                  "bg-green-500": order.Status === "open",
                                  "bg-orange-500": order.Status === "due",
                                  "bg-roma-blue": order.Status === "paid",
                                }}
                              />
                              <p class="font-medium col-start-2">
                                {order.OrderID}
                              </p>
                            </div>
                            <dl class="text-sm md:hidden text-left">
                              <dt class="sr-only md:hidden">Status</dt>
                              <dd class="md:hidden grid grid-cols-[max-content_1fr] items-center gap-x-2 text-left">
                                <div
                                  class="w-3 aspect-square rounded-full"
                                  classList={{
                                    "bg-green-500": order.Status === "open",
                                    "bg-orange-500": order.Status === "due",
                                    "bg-roma-blue": order.Status === "paid",
                                  }}
                                />
                                <span>{order.OrderID}</span>
                              </dd>
                              <dt class="sr-only md:hidden">Date</dt>
                              <dd class="md:hidden">
                                Date: {order.Date === "" ? "-" : order.Date}
                              </dd>
                              <dt class="sr-only md:hidden">Invoice ID</dt>
                              <dd class="md:hidden">
                                Ref:{" "}
                                {"InvoiceID" in order && order?.InvoiceID
                                  ? order.InvoiceID
                                  : "-"}
                              </dd>
                              <Show
                                when={order.PONumber && order.PONumber !== ""}
                              >
                                <dt class="sr-only md:hidden">PO Number</dt>
                                <dd class="md:hidden">PO: {order.PONumber}</dd>
                              </Show>
                            </dl>
                          </td>
                          <td class="hidden md:table-cell">
                            {order.Date === "" ? "-" : order.Date}
                          </td>
                          <td class="hidden md:table-cell">
                            <p>
                              {"InvoiceID" in order && order.InvoiceID
                                ? order.InvoiceID
                                : "-"}
                            </p>
                          </td>
                          <td class="hidden md:table-cell">
                            <Show
                              when={order.PONumber && order.PONumber !== ""}
                            >
                              <p>{order.PONumber}</p>
                            </Show>
                          </td>
                          <td class="flex justify-center text-sm md:text-base">
                            <div class="text-right">
                              <p class="font-medium">
                                ${order.Amount.toFixed(2)}
                              </p>
                            </div>
                          </td>
                          <td>
                            <button
                              aria-label="Download"
                              disabled={downloadId() !== null}
                              onClick={async () => {
                                const type =
                                  order.Status === "open" ? "order" : "invoice";
                                await downloadPDF(
                                  type === "order"
                                    ? order.OrderID
                                    : (order as Invoice).InvoiceID,
                                  type
                                );
                              }}
                            >
                              <Show
                                fallback={
                                  <Icon
                                    path={arrowDownTray}
                                    class="w-6 text-roma-blue"
                                  />
                                }
                                when={
                                  (order.Status === "open" &&
                                    downloadId() === order.OrderID) ||
                                  (order.Status !== "open" &&
                                    downloadId() === order.InvoiceID)
                                }
                              >
                                <BaseLoader
                                  width={6}
                                  height={6}
                                  class="!mr-0"
                                />
                              </Show>
                            </button>
                          </td>
                        </tr>
                      )}
                    </For>
                  </Show>
                </tbody>
              </table>
            </main>
          </Suspense>
        </div>
      </AuthBarrier>
    </AccountPanel>
  );
}
